@import '../node_modules/prismjs/themes/prism-tomorrow';
@import './assets/scss/app.scss';

.dropdown-menu button {
  display: block !important;
  width: 100% !important;
}

* {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
}

.card-body.scroll {
}

@media (max-width: 801px) {
  .card-body.scroll {
    overflow-x: auto;
    overflow-y: hidden;

    .btn.add {
      display: flex;
      margin-left: 20px !important;
      margin-right: 20px !important;
    }

    .btn.filter {
      margin-right: 10px;
    }
  }
}

.DateRangePickerInput {
  min-width: 290px;
}

.pointer {
  cursor: pointer;
}

.btn-sm {
  font-size: 12px;
}

.btn-invoice {
  background-color: #1777d8 !important;
  border-color: #0364be !important;
}

.withdraws-scroll {
  max-height: 350px;
  overflow-y: scroll;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.badge-black {
  background-color: #000;
  color: #fff;
}
